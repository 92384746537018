import { navigate } from "gatsby"
import React, { useContext } from "react"
import { LANGUAGES } from "../../constants"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"
import Job from "../Job/Job"

const JobsContainer = ({ data }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  return (
    <>
      <h2 className="jobs-title">
        {`${translateText(
          "Job Openings",
          currentLanguage.fullTitle,
          googleSheetData
        )}`}
      </h2>

      {data.map(job => {
        const { id } = job.node[currentLanguage.label.toLowerCase()]

        return <Job key={id} data={job.node[currentLanguage.label.toLowerCase()]} />
      })}

      <div className="jobs-link">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => navigate(`${routePrefix}${"/job-listing"}`)}
        >
          {`${translateText(
            "See all jobs",
            currentLanguage.fullTitle,
            googleSheetData
          )}`}
        </button>
      </div>
    </>
  )
}

export default JobsContainer
