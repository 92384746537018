import homePageZigZagImage1 from "../assets/images/home-page/zigzag-image1.png"
import homePageZigZagImage2 from "../assets/images/home-page/zigzag-image2.png"
import homePageZigZagImage3 from "../assets/images/home-page/zigzag-image3.png"

import recruitingPageZigZagImage1 from "../assets/images/recruiting-page/zigzag-image1.png"
import recruitingPageZigZagImage2 from "../assets/images/recruiting-page/zigzag-image2.png"
import recruitingPageZigZagImage3 from "../assets/images/recruiting-page/zigzag-image3.png"
import recruitingPageZigZagImage4 from "../assets/images/recruiting-page/zigzag-image4.png"
import recruitingPageZigZagImage5 from "../assets/images/recruiting-page/zigzag-image5.png"

import numIcon1 from "../assets/images/num1-icon.svg"
import numIcon2 from "../assets/images/num2-icon.svg"
import numIcon3 from "../assets/images/num3-icon.svg"
import numIcon4 from "../assets/images/num4-icon.svg"
import numIcon5 from "../assets/images/num5-icon.svg"
import numIcon6 from "../assets/images/num6-icon.svg"

import contactPageZigZagImage from "../assets/images/contact-page/contact-zig-zag-img.png"
import engineering2ZigZag1Image from "../assets/images/engineering-pages/engineering2-zig-zag1.png"
import engineering2ZigZag2Image from "../assets/images/engineering-pages/engineering2-zig-zag2.png"
import engineering2ZigZag3Image from "../assets/images/engineering-pages/engineering2-zig-zag3.png"
import engineering2ZigZag4Image from "../assets/images/engineering-pages/engineering2-zig-zag4.png"
import engineering2ZigZag5Image from "../assets/images/engineering-pages/engineering2-zig-zag5.png"
import engineering4ZigZagImage from "../assets/images/engineering-pages/engineering4-zig-zag.png"
import engineering5ZigZagImage from "../assets/images/engineering-pages/engineering5-zig-zag.png"
import gifImage from "../assets/images/gif-images/gif-engineering5-2.svg"

export const homePageZigZagData = [{
    heading: "Software Engineering",
    body: "We build state-of-the-art software for different companies, suitable to their needs and budget. Customer satisfaction and optimal user experience is at the forefront of all our decisions.",
    images: [{
      src: homePageZigZagImage1,
      gif: gifImage
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
  {
    heading: "IT-Recruitment",
    body: "We found out that getting engineers with advanced soft skills, to hire other engineers for companies is extremely efficient. So all our recruiters are knowledgeable in the fields they hire in. Engineers hire engineers, Designers hire other designers and so on.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
  {
    heading: "Shopify e-commerce",
    body: "Shopify is our go-to e-commerce system to build small online stores. This is a great solution for a business which starts small and begins to scale. On reaching the limits of Shopify we provide other solutions.",
    images: [{
      src: homePageZigZagImage3,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
]

export const recruitingPageZigZagData = [{
    heading: "Finding of Candidates",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: recruitingPageZigZagImage1,
    }, ],
    numIcon: numIcon1,
  },
  {
    heading: "Selecting of Candidates",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    images: [{
      src: recruitingPageZigZagImage2,
    }, ],
    numIcon: numIcon2,
  },
  {
    heading: "Conducting Interviews",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: recruitingPageZigZagImage3,
    }, ],
    numIcon: numIcon3,
  },
  {
    heading: "Accompany Company Interviews with Candidates",
    body: "Coaching of candidates before and after every interview Managing expectation of clients/company before and after every interview",
    images: [{
      src: recruitingPageZigZagImage4,
    }, ],
    numIcon: numIcon4,
  },
  {
    heading: "Salary negotiations",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: recruitingPageZigZagImage5,
    }, ],
    numIcon: numIcon5,
  },
   {
     heading: "Accompany Company Interviews with Candidates",
     body: "Coaching of candidates before and after every interview Managing expectation of clients/company before and after every interview",
     images: [{
       src: recruitingPageZigZagImage4,
     }, ],
     numIcon: numIcon6,
   }
]

export const contactPageZigZagData = [{
    heading: "Berlin Office",
    body: "Wicherstr. 52, 10439 Berlin, Germany,+49 221 25912509 hello@dzemo.com",
    sideOfImage: "left",
    images: [{
      src: contactPageZigZagImage,
    }, ],

  },

]

export const blogPageZigZagData = [{
    heading: "Let’s work together",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },

]

export const projectMobileCaseZigZagData = [{
    heading: "Let’s work together",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },

]

export const shopApothekeRecruitingZigZagData = [{
    heading: "Let’s work together",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },

]

export const cnqrVisualisationSolutionZigZagData = [{
    heading: "Let’s work together",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },

]


export const engineeringPage1ZigZagData = [{
    heading: "External IT Department",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
  {
    heading: "Software Development",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
  {
    heading: "eCommerce Shop Implementation",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
  {
    heading: "RPA and AI",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    images: [{
      src: homePageZigZagImage2,
    }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },
]

export const engineeringPage2ZigZagData = [{
    heading: "IT Security: VPN, Firewall, ...",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: engineering2ZigZag1Image,
    }, ]
  },
  {
    heading: "Email Setup",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    images: [{
      src: engineering2ZigZag2Image,
    }, ]
  },
  {
    heading: "Cloud Storage",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur.",
    sideOfImage: "left",
    images: [{
      src: engineering2ZigZag3Image,
    }, ]
  },
  {
    heading: "Company Wiki Setup",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    images: [{
      src: engineering2ZigZag4Image,
    }, ]
  },
  {
    heading: "Operating Systems & Hardware",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: engineering2ZigZag5Image,
    }, ]
  },
]




export const engineeringPage4ZigZagData = [{
    heading: "Implementations of lighter Shops using Shopify",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: engineering4ZigZagImage,
    }, ],

  },

]


export const engineeringPage5ZigZagData = [{
    heading: "Artificial Intelligence and Machine Learning",
    body: "Coaching of candidates before and after every interview. Managing expectation of clients / company before and after every interview ",

    images: [{
      src: engineering5ZigZagImage,
    }, ],

  },

]



export const caseArticlePageZigZagData= [{
    heading: "Let’s work together",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor et velit a pellentesque. Donec hendrerit vitae lorem non efficitur. Fusce lobortis quis leo vitae pulvinar. Mauris auctor et velit.",
    sideOfImage: "left",
    images: [{
      src: homePageZigZagImage2,
      }, ],
    button: {
      title: "Learn more",
      link: "/learn-more",
    },
  },

]
