import React, { useContext } from "react"
import { LanguageContext } from "../../contexts/languageContext"
import { useLocalizedData } from "../../hooks/useLocalizedData"
import { getLocalizedField } from "../../utils/getLocalizedField"
import "./Job.scss"

/*
@param image - String, eg. "static/images/example-image.png"
@param title - String, eg. "Example title"
@param text - String, eg. "Example text"
*/
const Job = ({ data }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const localizedData = useLocalizedData(data, currentLanguage.key)

  const { client, city, country } = localizedData

  const localizedJobName =
    localizedData["name"] &&
    localizedData[getLocalizedField("name", currentLanguage.label)]
  const { logo } = client
  const { publicUrl } = client.staticLogo
  const { name: clientName } = client
  const { name: cityName } = city
  const { name: countryName } = country

  return (
    <div className="job-content">
      <img
        className="job-img"
        src={`${publicUrl}`}
        alt={logo.alternativeText}
        width="62"
        height="62"
      />
      <h3 className="job-title">{localizedJobName}</h3>
      <p className="job-text">{clientName}</p>
      <p className="job-text">{`${cityName}, ${countryName}`}</p>
    </div>
  )
}

export default Job
