export const useLocalizedData = (data, languageSuffix) => {
  if (Array.isArray(data)) {
    return localizeArray(data, languageSuffix)
  } else if (data) {
    return localizeObject(data, languageSuffix)
  }
}

const localizeArray = (data, languageSuffix) => {
  let localizedData = []
  data.forEach(item => {
    localizedData.push(localizeObject(item, languageSuffix))
  })
  return localizedData
}

const localizeObject = (item, languageSuffix) => {
  let localizedObject = {}
  Object.keys(item).forEach(key => {
    const isFieldForLocalization = FIELDS_FOR_LOCALIZATION.find(
      field => field === key
    )
      ? true
      : false

    const isFieldArray = Array.isArray(item[key]) && item[key].length > 0
    //console.log("LOC KEY", item[key])
    if (isFieldArray) {
      localizedObject[key] = localizeArray(item[key], languageSuffix)
    } else if (isFieldForLocalization) {
      const localizedKey = `${key}${languageSuffix ? `_${languageSuffix}` : ""}`
      if (item[key] && !item[key][key]) {
        localizedObject[key] = item[localizedKey]
          ? item[localizedKey]
          : item[localizedKey.toLowerCase()]
      } else if (item[key]) {
        localizedObject[key] = item[key][localizedKey]
      }
    } else {
      localizedObject[key] = item[key]
    }
    //console.log("CURR OBJ", localizedObject)
  })
  return localizedObject
}

const FIELDS_FOR_LOCALIZATION = [
  "name",
  "text",
  "title",
  "body",
  "teaserText",
  "abstract",
  "webAddress",
  "address",
  "legal",
  "taxId",
  "represented",
  "register",
  "according",
  "title1",
  "title2",
  "text1",
  "text2",
  "component",
  "shortBio",
  "username",
  "meta_description",
]
