import React, { useMemo } from "react"
import "./Recruiting.scss"
import TopComponent from "../../components/TopComponent/TopComponent"
import { recruitingPageZigZagData } from "../../data/zigzag-element-data"
import WeUse from "../../components/WeUse/WeUse"
import ZigZag from "../../components/ZigZag/ZigZag"
import Contact from "../../components/Contact/Contact"
import { Layout } from "../../layouts"
import Hero from "../../components/Hero/Hero"
import ChyronItem from "../../components/ChyronItem/ChyronItem"
import Testimonial from "../../components/Testimonial/Testimonial"
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider"
import { graphql } from "gatsby"
import gifImage from "../../assets/images/gif-images/gif-engineering5-1.svg"
import { MESSAGE_TYPES } from "../../constants"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import SEO from "../../components/Seo"
import { localizeDataNodes, localizeStaticNodes } from "../../utils/localizeDataNodes"
import JobsContainer from "../../components/JobsContainer/JobsContainer"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import SubMenu from "../../components/SubMenu/SubMenu"
import { MENUS_IDS } from "../../constants"

const ZIGZAG_IMAGE_SIDE_START = "left"

const Recruiting = ({ pageContext: { pageData, lang, testimonials, jobs, companies, clients }, data, location }) => {
  const {
    allDzemoApiArticles,
    allDzemoApiZigzags,
    allDzemoApiPages,
    allDzemoApiGenerals,
  } = useMemo(() => localizeDataNodes(data, lang), [data, lang])

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )

  const recruitingSubPagesGenerals =
    allDzemoApiGenerals
      .filter(({ menu }) => menu?.id === MENUS_IDS.recruitingMenuId)
      .sort((a, b) => (a.weight < b.weight ? -1 : 1)) ?? []

  const recruitingSubPagesPages =
    allDzemoApiPages
      .filter(({ menu }) => menu?.id === MENUS_IDS.recruitingMenuId)
      .sort((a, b) => (a.weight < b.weight ? -1 : 1)) ?? []

  const allSubPages = recruitingSubPagesGenerals
    .concat(recruitingSubPagesPages)
    .sort((a, b) => (a.weight < b.weight ? -1 : 1))

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])
  const localizedZigzags = useMemo(() => localizeStaticNodes(pageData[lang].zigzags, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <SubMenu data={allSubPages} location={location} />
      <div className="recruiting-page">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent data={localizedTops} imageFormatName={"normal"} />
          )}
        </section>

        <section className="chyron-container">
          <TestimonialSlider chyron={true}>
            {clients.map(client => {
              const { logo, webAddress, staticLogo } = client.node[lang]
              const { alternativeText } = logo
              const { publicUrl } = staticLogo

              return (
                <ChyronItem
                  key={`id-${lang}`}
                  data={{
                    src: `${publicUrl}`,
                    alt: alternativeText,
                    url: webAddress,
                  }}
                />
              )
            })}
          </TestimonialSlider>
        </section>

        {pageData[lang].videos.length > 0 && (
          <section>
            <Hero data={pageData[lang].videos[0]} />
          </section>
        )}

        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          <div className="section-we-use">
            {allDzemoApiArticles.length > 0 && (
              <WeUse data={allDzemoApiArticles[0]} gif={gifImage} />
            )}
          </div>

          {localizedZigzags.length > 0 &&
            localizedZigzags.map((zigzag, index) => {
              return (
                <ZigZag
                  key={zigzag.id}
                  data={{
                    ...zigzag,
                    image: zigzag.image[0],
                    sideOfImage: zigzagImageSides[index],
                    numIcon: recruitingPageZigZagData.map(
                      ({ numIcon }) => numIcon
                    )[index],
                    wave: index === 3 ? true : undefined,
                  }}
                />
              )
            })}
        </section>

        <section
          style={{
            background: "rgba(241, 241, 241, 1)",
            minHeight: "717px",
          }}
        >
          <TestimonialSlider>
            {testimonials.map(testimonial => {
              const { id } = testimonial
              return <Testimonial key={`${id}-${lang}`} data={testimonial.node[lang]} />
            })}
          </TestimonialSlider>
        </section>

        <section className="jobs-container">
          <JobsContainer data={jobs} />
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export default Recruiting

export const query = graphql`
  query recruitingPageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiVideos(
      filter: { en: { page: { id: { eq: $pageId } } } }
      limit: 1
    ) {
      nodes {
        en {
          id
          title
          video {
            alternativeText
            ext
            hash
            id
            name
            size
            url
          }
        }
        de {
          id
          title
          video {
            alternativeText
            ext
            hash
            id
            name
            size
            url
          }
        }
      }
    }

    allDzemoApiArticles(
      filter: { en: { page: { id: { eq: $pageId } } } }
      limit: 1
    ) {
      nodes {
        en {
          id
          body
          title
        }
        de {
          id
          body
          title
        }
      }
    }

    allDzemoApiClients {
      nodes {
        en {
          id
          webAddress
          logo {
            caption
            alternativeText
            id
            name
            size
            url
            width
            height
          }
        }
        de {
          id
          webAddress
          logo {
            caption
            alternativeText
            id
            name
            size
            url
            width
            height
          }
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { page: { id: { eq: $pageId } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }

    allDzemoApiJobs(sort: { fields: en___created_at, order: DESC }, limit: 5) {
      nodes {
        en {
          id
          name {
            name
            name_de
          }
          body
          client {
            name
            name_de
            logo {
              width
              height
              url
              name
            }
          }
          country {
            name
            name_de
          }
          city {
            name
            name_de
          }
        }
        de {
          id
          name {
            name
            name_de
          }
          body
          client {
            name
            name_de
            logo {
              width
              height
              url
              name
            }
          }
          country {
            name
            name_de
          }
          city {
            name
            name_de
          }
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
      }
    }

    allDzemoApiPages(sort: { order: ASC, fields: en___weight }) {
      nodes {
        en {
          id
          name
          template
          path
          metadescription
          weight
          subpages {
            id
            name
            path
            weight
          }
          menu {
            id
          }
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
        de {
          id
          name
          template
          path
          metadescription
          weight
          subpages {
            id
            name_de
            path_de
            weight
          }
          menu {
            id
          }
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
      }
    }

    allDzemoApiGenerals(sort: { fields: en___created_at, order: DESC }) {
      nodes {
        en {
          id
          name
          menu {
            id
          }
          route
          weight
          created_at(formatString: "MMM d, yyyy")
          image {
            formats {
              blog {
                url
                name
                hash
                width
                height
              }
              blog_lg {
                hash
                size
                url
                width
                name
              }
              blog_sm {
                height
                hash
                name
                url
                width
              }
            }
            alternativeText
          }
          abstract
          body
          teaserText
          profile {
            name
            name_de
            linkedin
            position
            shortBio
            shortBio_de
            email
            image {
              formats {
                team_mini {
                  url
                  width
                  name
                  height
                  hash
                }
                team_small {
                  url
                  width
                  name
                  height
                  hash
                }
              }
              alternativeText
            }
          }
          metadescription
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
        de {
          id
          name
          menu {
            id
          }
          route
          weight
          created_at(formatString: "MMM d, yyyy")
          image {
            formats {
              blog {
                url
                name
                hash
                width
                height
              }
              blog_lg {
                hash
                size
                url
                width
                name
              }
              blog_sm {
                height
                hash
                name
                url
                width
              }
            }
            alternativeText
          }
          abstract
          body
          teaserText
          profile {
            name
            name_de
            linkedin
            position
            shortBio
            shortBio_de
            email
            image {
              formats {
                team_mini {
                  url
                  width
                  name
                  height
                  hash
                }
                team_small {
                  url
                  width
                  name
                  height
                  hash
                }
              }
              alternativeText
            }
          }
          metadescription
          icon {
            url
            width
            height
            hash
            alternativeText
          }
        }
      }
    }
  }
`
